// src/components/PlayerStats.js
import React from 'react';
import { useParams } from 'react-router-dom';

const PlayerStats = () => {
    const { playerName } = useParams();
    
    // You can fetch player data based on playerName or use static data for now
    return (
        <div>
            <h2>Player Stats for {playerName}</h2>
            {/* Display player stats here */}
        </div>
    );
};

export default PlayerStats;
