import React, { useState, useEffect, useRef } from 'react';
import './MatchReplay.css';
import * as d3 from 'd3';
import { FaPlay, FaPause, FaFastForward, FaFastBackward } from 'react-icons/fa';

const MAP_IMAGE = '/images/polus.jpg';
const PLAYER_IMAGE_BASE = '/images/players/';
const DEAD_PLAYER_IMAGE_BASE = '/images/deadPlayers/';
const KNIFE_IMAGE = '/images/events/knife.png';
const VENT_IMAGE = '/images/events/vent.png';
const REPORT_IMAGE = '/images/events/report.png';

const MatchReplay = () => {
    const [movements, setMovements] = useState([]);
    const [matchData, setMatchData] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playSpeed, setPlaySpeed] = useState(100);
    const [matchId, setMatchId] = useState('');
    const [currentEventIndex, setCurrentEventIndex] = useState(0);
    const [gameStarted, setGameStarted] = useState(false);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const [eventIcons, setEventIcons] = useState({});
    const [isMeeting, setIsMeeting] = useState(false);

    const svgRef = useRef();
    const playerIcons = useRef({});
    const playerNames = useRef({});
    const playAnimationRef = useRef(null);
    const currentEventIndexRef = useRef(0);
    const lastEventTimeRef = useRef(0);

    const fetchMovements = async (matchId) => {
        try {
            const res = await fetch(`/api/match/${matchId}/movements/`);
            const data = await res.json();
            console.log('Movements Data:', data);
            setMovements(data);
        } catch (error) {
            console.error('Error loading movements:', error);
        }
    };

    const fetchMatchData = async (matchId) => {
        try {
            const res = await fetch(`/api/match/${matchId}/data/`);
            const data = await res.json();
            console.log('Match Data:', data);
            setMatchData(data);
        } catch (error) {
            console.error('Error loading match data:', error);
        }
    };

    const renderMap = () => {
        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();

        svg.append('image')
            .attr('xlink:href', MAP_IMAGE)
            .attr('width', 800)
            .attr('height', 600)
            .attr('class', 'map-image');

        console.log('Map rendered');
    };

    const removePlayer = (playerName) => {
        if (playerIcons.current[playerName]) {
            const playerColor = playerIcons.current[playerName].attr('data-color');
            const deadBodyIcon = `${DEAD_PLAYER_IMAGE_BASE}${playerColor}_dead.png`;
            
            playerIcons.current[playerName]
                .attr('xlink:href', deadBodyIcon)
                .attr('width', 30)
                .attr('height', 30);

            playerNames.current[playerName].remove();
            delete playerNames.current[playerName];
            
            console.log(`Player ${playerName} changed to dead body`);
        } else {
            console.log(`Player ${playerName} not found for removal`);
        }
    };

    const updatePlayerPositions = (movementEvent) => {
        if (!matchData) {
            console.log('Match data not loaded yet');
            return;
        }

        const { Player, Location, Event } = movementEvent;
        if (Event === 'Death') {
            removePlayer(Player);
            return;
        }

        if (!Player || !Location) {
            console.log('Skipping invalid movement event:', movementEvent);
            return;
        }

        const [x, y] = Location.split(',').map(Number);
        if (isNaN(x) || isNaN(y)) {
            console.log('Invalid coordinates for player:', Player, 'Location:', Location);
            return;
        }

        const playerColorMap = {};
        const players = matchData.Players.split(',').map((player) => player.trim());
        const colors = matchData.Colors.split(',').map((color) => color.trim());

        players.forEach((player, index) => {
            playerColorMap[player] = colors[index];
        });

        const playerColor = playerColorMap[Player.trim()];
        if (!playerColor) {
            console.log(`No color found for player: ${Player}`);
            return;
        }

        const playerIcon = `${PLAYER_IMAGE_BASE}${playerColor}_crewmate.png`;
        console.log(`Player: ${Player}, Color: ${playerColor}, Icon: ${playerIcon}, Position: (${x}, ${y})`);

        const svg = d3.select(svgRef.current);

        if (!playerIcons.current[Player]) {
            console.log(`Creating player icon for ${Player}`);
            playerIcons.current[Player] = svg.append('image')
                .attr('xlink:href', playerIcon)
                .attr('width', 20)
                .attr('height', 20)
                .attr('x', mapXToCanvas(x))
                .attr('y', mapYToCanvas(y))
                .attr('class', 'player-icon')
                .attr('data-color', playerColor); 

            playerNames.current[Player] = svg.append('text')
                .attr('x', mapXToCanvas(x) + 10)
                .attr('y', mapYToCanvas(y) + 30)
                .attr('fill', playerColor)
                .attr('font-size', '12px')
                .attr('text-anchor', 'middle')
                .text(Player);
        } else {
            console.log(`Updating position for player ${Player}`);
            playerIcons.current[Player]
                .transition()
                .duration(playSpeed - 20)
                .attr('x', mapXToCanvas(x))
                .attr('y', mapYToCanvas(y));

            playerNames.current[Player]
                .transition()
                .duration(playSpeed - 20)
                .attr('x', mapXToCanvas(x) + 10)
                .attr('y', mapYToCanvas(y) + 30);
        }
    };

    const mapXToCanvas = (x) => {
        const xLeftBorder = 0;
        const xRightBorder = 0;
        const mapWidth = 800;
        const leftX = 0;
        const rightX = 42.00;

        const mappedX = ((x - (leftX + xLeftBorder)) / (rightX - xRightBorder - leftX - xLeftBorder)) * mapWidth;

        console.log(`Mapping X: ${x} to Canvas X: ${mappedX}`);
        return mappedX;
    };

    const mapYToCanvas = (y) => {
        const yTopBorder = 0;
        const yBottomBorder = 0;
        const mapHeight = 600;
        const topY = 3;
        const bottomY = -28.0;

        const mappedY = ((topY + yTopBorder - y) / (topY + yTopBorder - (bottomY - yBottomBorder))) * mapHeight;

        console.log(`Mapping Y: ${y} to Canvas Y: ${mappedY}`);
        return mappedY;
    };

    const handleSpeedChange = (speed) => {
        setPlaybackSpeed(speed);
        setPlaySpeed(100 / speed);
    };

    const displayEventIcon = (event, x, y) => {
        if (typeof x !== 'number' || typeof y !== 'number' || isNaN(x) || isNaN(y)) {
            console.log(`Cannot display ${event} icon: invalid coordinates`, {x, y});
            return;
        }

        const svg = d3.select(svgRef.current);
        let iconImage;
        
        switch (event) {
            case 'Death':
                iconImage = KNIFE_IMAGE;
                break;
            case 'Vent':
                iconImage = VENT_IMAGE;
                break;
            case 'Report':
                iconImage = REPORT_IMAGE;
                break;
            default:
                console.log('Unknown event for icon display:', event);
                return;
        }
        
        const icon = svg.append('image')
            .attr('xlink:href', iconImage)
            .attr('x', mapXToCanvas(x) - 15)
            .attr('y', mapYToCanvas(y) - 15)
            .attr('width', 30)
            .attr('height', 30)
            .attr('class', 'event-icon');

        setEventIcons(prev => ({...prev, [event]: icon}));

        setTimeout(() => {
            icon.remove();
            setEventIcons(prev => {
                const newIcons = {...prev};
                delete newIcons[event];
                return newIcons;
            });
        }, 3000);
    };

    const playMatch = () => {
        const eventIndex = currentEventIndexRef.current;

        if (eventIndex >= movements.length) {
            cancelAnimationFrame(playAnimationRef.current);
            setIsPlaying(false);
            return;
        }

        const currentEvent = movements[eventIndex];
        const currentTime = Date.now();
        const timeSinceLastEvent = currentTime - lastEventTimeRef.current;

        if (timeSinceLastEvent < (currentEvent.TimeSinceStart - (movements[eventIndex - 1]?.TimeSinceStart || 0)) / playbackSpeed) {
            playAnimationRef.current = requestAnimationFrame(playMatch);
            return;
        }

        console.log(`Current Event Index: ${eventIndex}`, JSON.stringify(currentEvent, null, 2));

        try {
            switch (currentEvent.Event) {
                case 'Movement':
                    if (!isMeeting && currentEvent.Location) {
                        updatePlayerPositions(currentEvent);
                    }
                    break;
                case 'StartGame':
                    if (!gameStarted) setGameStarted(true);
                    break;
                case 'Death':
                    if (currentEvent.Name) {
                        removePlayer(currentEvent.Name);
                        const playerIcon = playerIcons.current[currentEvent.Name];
                        if (playerIcon) {
                            const x = parseFloat(playerIcon.attr('x'));
                            const y = parseFloat(playerIcon.attr('y'));
                            displayEventIcon('Death', x, y);
                        } else {
                            console.log('Death event for player with no icon:', currentEvent.Name);
                        }
                    } else {
                        console.log('Death event without player name:', currentEvent);
                    }
                    break;
                case 'Vent':
                    if (currentEvent.Location) {
                        const [x, y] = currentEvent.Location.split(',').map(Number);
                        displayEventIcon('Vent', x, y);
                    } else {
                        console.log('Vent event without location:', currentEvent);
                    }
                    break;
                case 'Report':
                    if (currentEvent.Location) {
                        const [x, y] = currentEvent.Location.split(',').map(Number);
                        displayEventIcon('Report', x, y);
                    } else {
                        console.log('Report event without location:', currentEvent);
                    }
                    break;
                case 'MeetingStart':
                    setIsMeeting(true);
                    break;
                case 'MeetingEnd':
                    setIsMeeting(false);
                    break;
                default:
                    console.log('Unhandled event:', currentEvent);
            }
        } catch (error) {
            console.error('Error processing event:', error);
            console.error('Problematic event:', JSON.stringify(currentEvent, null, 2));
        }

        currentEventIndexRef.current += 1;
        setCurrentEventIndex(currentEventIndexRef.current);
        lastEventTimeRef.current = currentTime;

        playAnimationRef.current = requestAnimationFrame(playMatch);
    };
    
    const handleMatchSubmit = (e) => {
        e.preventDefault();
        if (matchId) {
            setMovements([]);
            setMatchData(null);
            setCurrentEventIndex(0);
            currentEventIndexRef.current = 0;
            setIsPlaying(false);
            setGameStarted(false);
            playerIcons.current = {};
            playerNames.current = {};

            console.log(`Fetching data for match ID: ${matchId}`);
            fetchMatchData(matchId);
            fetchMovements(matchId);
        }
    };

    const handlePlay = () => {
        if (!isPlaying) {
            playAnimationRef.current = requestAnimationFrame(playMatch);
        } else {
            cancelAnimationFrame(playAnimationRef.current);
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        if (matchData) {
            renderMap();
            lastEventTimeRef.current = Date.now();
        }
    }, [matchData]);

    return (
        <div className="match-replay-container">
            <h1>Match Replay</h1>

            <form onSubmit={handleMatchSubmit} className="match-selector">
                <label htmlFor="matchId">Enter Match ID:</label>
                <input
                    type="number"
                    id="matchId"
                    value={matchId}
                    onChange={(e) => setMatchId(e.target.value)}
                    placeholder="Enter match ID"
                    required
                />
                <button type="submit">Load Match</button>
            </form>

            <div className="map-container">
                <svg ref={svgRef} width="800" height="600" />
            </div>

            <div className="video-controls">
                <button onClick={() => handleSpeedChange(0.5)} disabled={playbackSpeed === 0.5}>
                    <FaFastBackward />
                </button>
                <button onClick={handlePlay}>
                    {isPlaying ? <FaPause /> : <FaPlay />}
                </button>
                <button onClick={() => handleSpeedChange(2)} disabled={playbackSpeed === 2}>
                    <FaFastForward />
                </button>
                <span>Speed: {playbackSpeed}x</span>
            </div>

            <div className="progress-bar">
                <input
                    type="range"
                    min="0"
                    max={movements.length - 1}
                    value={currentEventIndex}
                    onChange={(e) => {
                        const newIndex = parseInt(e.target.value, 10);
                        currentEventIndexRef.current = newIndex;
                        setCurrentEventIndex(newIndex);
                    }}
                />
            </div>
        </div>
    );
};

export default MatchReplay;